<template>
  <get-report page-type="maintenance"></get-report>
</template>
<script>
import GetReport from 'V/dsjfzjc/aiNetwork/report/Index.vue'
export default {
  components: {
    GetReport
  }
}
</script>